import React from 'react';
import { HintTooltip } from '~/components/data-displays/HintTooltip';
import { ErrorMessage } from '~/components/data-entries/ErrorMessage';
import * as styles from './index.styles';

export type LabelDescription = {
  text: string;
  type: 'alert' | 'note' | 'notice';
};

export type Props = {
  label: string;
  htmlFor?: string;
  children?: React.ReactNode;
  tooltipDesc?: {
    id: string;
    text: React.ReactNode;
    place?: 'bottom' | 'top' | 'left' | 'right';
  };
  labelDesc?: LabelDescription;
  explanation?: string;
  errorMessage?: string;
};

export const TESTID = {
  ERROR_MESSAGE: 'ERROR_MESSAGE',
  TOOLTIP: 'TOOLTIP',
  LABEL_DESCRIPTION: 'LABEL_DESCRIPTION',
} as const;

export const MESSAGE = {
  TOOL_TIP: 'ツールチップ',
} as const;

export const Label: React.FC<Props> = (props: Props) => {
  const tooltipId = `tooltip-${props.tooltipDesc?.id}`;

  return (
    <div css={styles.container}>
      <div css={styles.wrapper}>
        <div css={styles.labelPrimary}>
          <label css={styles.label} htmlFor={props.htmlFor}>
            {props.label}
          </label>

          {props.tooltipDesc && (
            <span css={styles.tooltip}>
              <HintTooltip
                tooltipId={tooltipId}
                place={
                  props.tooltipDesc?.place
                    ? props.tooltipDesc.place
                    : typeof props.tooltipDesc.text === 'string'
                      ? 'bottom'
                      : undefined
                }
                testId={TESTID.TOOLTIP}
              >
                {props.tooltipDesc.text}
              </HintTooltip>
            </span>
          )}
        </div>
        {props.labelDesc && (
          <span
            css={styles.labelDesc({ type: props.labelDesc.type })}
            data-testid={TESTID.LABEL_DESCRIPTION}
          >
            {props.labelDesc.text}
          </span>
        )}
      </div>

      {props.errorMessage && (
        <ErrorMessage errorMessage={props.errorMessage} data-testid={TESTID.ERROR_MESSAGE} pt={0} />
      )}

      {props.explanation && <span css={styles.explanation}>{props.explanation}</span>}

      {props.children && <div css={styles.childContainer}>{props.children}</div>}

      {/* {props.validations && (
        <ul css={styles.validationList}>
          {props.validations
            .filter((validation) => validation.show)
            .map((validation) => (
              <li key={validation.message}>
                {validation.isValid ? '○' : '☓'}
                {validation.message}
              </li>
            ))}
        </ul>
      )} */}
    </div>
  );
};

